import {IConnectionInfo, IPacketScene, ITopicInfo} from "@/layouts/workspace/follow/device_session_live/typings";


export function parseTopic(topic: string): ITopicInfo {
    const ts = topic.split('/')
    return {did: ts[2], phone_number: ts[3], platform: ts[1], role: Number(ts[5]), sid: Number(ts[4])}
}

export function mergeToSession(topic: ITopicInfo, ps: IPacketScene): IConnectionInfo {
    return {
        desc: `${ps.title}-${new Date(ps.at).toLocaleTimeString()}`,
        did: topic.did,
        icon: topic.platform === 'android' ? 'android' : 'ios',
        phone_number: topic.phone_number,
        platform: topic.platform,
        role: topic.role,
        sid: topic.sid,
        title: `账号：${topic.phone_number}/${topic.sid}`
    }
}
