
/**
 * Token刷新
 * @param token
 */
import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {IDeviceInfo, ITopicInfo} from "@/layouts/workspace/follow/device_session_live/typings";

export async function getDeviceInfo(info: ITopicInfo): Promise<IDeviceInfo> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "device/live/info",
        method: 'post',
        data: info
    } as AxiosRequestConfig));
    return rs.data;
}
